html,
body {
  height: 100%;
  font-family: sans-serif;
  background: transparent !important;
}

body {
  margin: 0px;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
  overflow: hidden;
}

.row {
  margin-bottom: -4px;
}
